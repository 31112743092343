<template>
  <v-pagination
    v-if="count > perPage"
    :total-visible="7"
    class="mt-2"
    :length="countPages"
    :value="Number(value)"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'BasePagination',
  props: {
    value: [String, Number], //это страница
    count: Number, //к-во элем.
    perPage: {
      type: Number,
      default: 10
    } //к-во отображаемых элем.
  },
  computed: {
    countPages: function () {
      return Math.ceil(this.count / this.perPage)
    }
  }
}
</script>