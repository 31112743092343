<template>
  <div
    class="blue lighten-5 d-flex justify-space-between rounded mb-1"
  >
    <div
      role="button"
      @click.passive.stop="$emit('click')"
      class="pa-2"
      style="flex-grow: 2"
    >
      {{ value.label }}
    </div>
    <div class="pa-2">
      <!--<v-tooltip
        v-for="item in tooltips"
        :key="item.text"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-if="item.isActive"
            :color="item.color"
          >{{ item.icon }}
          </v-icon>
        </template>
        {{ item.text }}
      </v-tooltip>-->

      <v-progress-circular
        v-if="isLoading"
        size="20"
        indeterminate
        color="orange"
      ></v-progress-circular>
      <BaseBtn
        small
        :color="isAdded ? 'success' : 'error'"
        :title="isAdded ? 'Использовать' : 'Не использовать'"
        @click="isAdded ? onLayer() : offLayer()"
      />
      <!--      <v-icon
              @click="isDialogEdit = true"
              color="primary"
              class="ml-4"
              v-if="isCanInteractionPassport"
            >mdi-pencil
            </v-icon>-->
      <v-icon
        @click="isDialogDelete = true"
        color="primary"
        class="ml-4"
        :disabled="!isAdded"
        v-if="isCanInteractionPassport"
      >mdi-delete-outline
      </v-icon>
    </div>
    <BaseDialog
      role="delete"
      v-model="isDialogDelete"
      is-cancel-btn
      is-confirm-btn
      :max-width="300"
      :text="$t('message.areYouSure')"
      @confirm="handleDelete"
      :item-id="value.id"
    />
    <BaseDialog
      v-model="isDialogEdit"
      is-confirm-btn
      is-cancel-btn
      label="Изменение"
      text-cancel="Отмена"
      text-confirm="Ок"
      :max-width="500"
      @confirm="handleSave"
    >
      <template #content>
        <v-text-field
          class="mt-2"
          clearable
          outlined
          label="название слоя"
          v-model="value.label"
          dense
        />
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import BaseDialog from '@/components/base/BaseDialog'
import userInteraction from '@/components/mixins/userInteraction'
import qgis from '@/components/mixins/qgis'

export default {
  name: 'ListItem',
  components: { BaseBtn, BaseDialog },
  mixins: [userInteraction, qgis],
  props: {
    value: Object,
    isError: Boolean,
    isActiveMap: Boolean
  },
  data () {
    return {
      isDialogEdit: false,
      isDialogDelete: false,
      isLoading: false,
      tooltips: [
        {
          text: 'Ошибка',
          isActive: this.isError,
          color: 'red',
          icon: 'mdi-exclamation-thick'
        },
        {
          text: 'Активно для карты',
          isActive: this.isActiveMap,
          color: 'green',
          icon: 'mdi-map'
        }
      ]
    }
  },
  computed: {
    isAdded () {
      return this.value.is_added === 0
    }
  },
  methods: {
    async handleSave () {
      if (!this.isLoading) {
        this.isLoading = true
        this.isDialogEdit = false
        let response = await this.$store.dispatch('api/post', {
          url: '/map/layer/update',
          data: {
            query: {
              id: this.value.id,
              params: {
                label: this.value.label
              }
            }
          }
        }, { root: true })
        if (response.success) {
          this.$emit('save')
        } else {
          await this.$store.dispatch('systemMessages/error', 'Не удалось обновить информацию', { root: true })
        }
        this.isLoading = false
      }
      return true
    },
    async handleDelete (data) {
      if (!this.isLoading) {
        this.isLoading = true
        await this.$store.dispatch('api/delete', { url: 'map/layer/delete?id=' + data }, { root: true })
        this.isLoading = false
        this.isDialogDelete = false
        this.$emit('save')
      }
      return true
    }
  }
}
</script>

<style>
  .timer {
    transform: rotate(190deg);
    transition: all 1s;
    animation-iteration-count: infinite;
  }
</style>
