<template>
  <BaseDialog
    label="Загрузка гео-слоя"
    v-model="showDialog"
    :max-width="700"
    is-cancel-btn
    text-cancel="Отмена"
  >
    <template #content>
      <v-text-field
        class="mt-2"
        clearable
        outlined
        label="Введите название слоя"
        v-model="params.name"
        dense
      />
      <VSubheader class="pa-0">
        Доступны для загрузки файлы (tif) до {{ bytesToSize(maxLayerSize, ' ') }}
      </VSubheader>

      <BaseImportUploader
        file-type="tif"
        v-model="params"
        url="tile/upload"
        ref="uploader"
        @complete="handleComplete"
        :max-file-size="maxLayerSize"
      />
      <BaseParserDetails
        ref="detail"
        :params="params"
        url-check="tile/result"
        @parse:completed="handleParserCompleted"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseImportUploader from '@/components/base/BaseImportUploader'
import importPanel from '@/components/mixins/importPanel'
import BaseParserDetails from '@/components/base/BaseParserDetails'
import BaseDialog from '@/components/base/BaseDialog'
import { maxLayerSize } from '@/config/common'
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [importPanel, formatters],
  name: 'LayerUpload',
  components: {
    BaseImportUploader,
    BaseParserDetails,
    BaseDialog
  },
  props: {
    value: Boolean,
    urlToUpload: String,
    fileType: String,
    passportId: Number
  },
  data () {
    return {
      maxLayerSize,
      params: {
        name: '',
        passportId: this.passportId
      }
    }
  },
  /*watch: {
    extraData: {
      handler () {
        this.params = this.extraData
      },
      deep: true
    }
  },*/
  methods: {
    handleParserCompleted () {
      this.$emit('parse:completed')
    }
  }
}
</script>

<style scoped>

</style>
