import Vue from 'vue'
import { QGIS_URL } from '@/config/baseUrl_local'

const config = {
  headers: {
    'Accept': 'application/json'
  }
}
const url = QGIS_URL + 'ogc/project/'

const addLayer = 'add_layer?layer='
const source = '&layerSource='
const projectId = '&project='

const deleteLayer = 'delete_layer?layer='

export default {
  data () {
    return {
      requestInterceptor: null
    }
  },
  props: {
    passportId: Number
  },
  methods: {
    async onLayer () {
      this.isLoading = true
      let qgisResponse = await Vue.axios.get(url + addLayer + this.value.label + source + this.value.source + projectId + this.passportId + '/project.qgs', config)
      if (qgisResponse.data.success) {
        let sendObject = {
          query: {
            id: this.value.id,
            params: {
              label: this.value.label,
              is_added: 1
            }
          }
        }
        let response = await this.$store.dispatch('api/put', {
          url: 'map/layer/update',
          data: sendObject
        })
        if (response.success) {
          await this.$store.dispatch('systemMessages/success', 'Слой готов к использованию')
        } else {
          await this.$store.dispatch('systemMessages/error', 'Не удалось включить слой')
        }
      } else {
        let textError
        qgisResponse.data.error ? textError = qgisResponse.data.error : textError = 'Не удалось подключиться к серверу слоя'
        await this.$store.dispatch('systemMessages/error', textError)
      }
      this.$emit('getLayers')
      if (this.$route.name === 'map') {
        this.$emit('updateLayers')
      }
      this.isLoading = false
    },
    async offLayer () {
      this.isLoading = true
      let qgisResponse = await Vue.axios.get(url + deleteLayer + this.value.label + projectId + this.passportId + '/project.qgs', config)
      if (qgisResponse.data.success) {
        let sendObject = {
          query: {
            id: this.value.id,
            params: {
              label: this.value.label,
              is_added: 0
            }
          }
        }
        let response = await this.$store.dispatch('api/put', {
          url: 'map/layer/update',
          data: sendObject
        })
        if (response.success) {
          await this.$store.dispatch('systemMessages/success', 'Слой отключен')
        } else {
          await this.$store.dispatch('systemMessages/error', 'Не удалось отключить слой')
        }
      } else {
        let textError
        qgisResponse.data.error ? textError = qgisResponse.data.error : textError = 'Не удалось подключиться к серверу слоя'
        await this.$store.dispatch('systemMessages/error', textError)
      }
      this.$emit('getLayers')
      if (this.$route.name === 'map') {
        this.$emit('updateLayers')
      }
      this.isLoading = false
    }
  }
}