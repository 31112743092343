export default {
  methods: {
    checkRangePage (page, index) {
      let result = false
      const range = [page * 10 - 9, page * 10]
      if (index + 1 >= range[0] && index + 1 <= range[1]) {
        result = true
      }
      return result
    }
  }
}