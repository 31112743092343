<template>
  <div>
    <BaseCard is-small-height>
      <template #header>
        <BaseHeader
          :save-btn="false"
          :close-btn="false"
          :delete-btn="false"
          title="Менеджер подложек"
        />
      </template>

      <template #content>
        <BasePreloader v-if="isLoading"/>

        <BaseBtn
          title="Добавить слой"
          v-if="!isLoading && isCanInteractionPassport"
          small
          color="primary"
          icon="mdi-plus"
          @click="isDialogLayer = true"
        />

        <ListItem
          class="mt-2"
          @click="handleClickItem(item)"
          v-for="(item, index) in passportLayers"
          :key="item.id + '' + index"
          v-if="checkRangePage(pagination.page, index) && !isLoading"
          :value="item"
          :passport-id="value.properties.id"
          is-error
          is-active-map
          is-in-progress
          @delete="isDialogDelete = true"
          @save="handleSave"
          @getLayers="getLayers"
          @updateLayers="updateLayers"
        />
<!--        <LayerUpload
          v-model="isDialogLayer"
          :passport-id="value.properties.id"
          @parse:completed="handleParseCompleted"
        />-->
        <BaseDialog
          v-model="isDialogLayer"
          is-cross-close
          label="Добавить слой"
          without-sides-padding
          little-label
          without-bot-padding
        >
          <template #content>
            <BaseImportPanel
              :file-type="fileType"
              :next-step-start-after="nextStepStartAfter"
              :previous-step-end-after="previousStepEndAfter"
              :params-to-upload="paramsToUpload"
              @parse:completed="handleParseCompleted"
            />
          </template>
        </BaseDialog>
        <BaseAlert
          v-if="!passportLayers.length"
          type="info"
          text="Нет данных"
          class="mt-3"
        />

        <BasePagination
          v-model="pagination.page"
          :count="passportLayers.length"
          :per-page="Number(pagination.perPage)"
          v-if="!isLoading"
        />
      </template>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from '@/components/base/BaseCard'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import ListItem from '@/components/views/account/passport/detail/views/passportLayers/ListItem'
import BaseBtn from '@/components/base/UI/BaseBtn'
import formatters from '@/components/mixins/formatters'
import BasePagination from '@/components/base/BasePagination'
import pagination from '@/components/mixins/pagination'
import BasePreloader from '@/components/base/UI/BasePreloader'
import LayerUpload from '@/components/views/account/passport/detail/views/passportLayers/LayerUpload'
import userInteraction from '@/components/mixins/userInteraction'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseImportPanel from '@/components/base/baseImportPanel/BaseImportPanel'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'PassportLayers',
  mixins: [formatters, pagination, userInteraction],
  components: {
    BaseDialog,
    BaseImportPanel,
    BaseAlert,
    LayerUpload,
    BasePreloader,
    BasePagination,
    BaseBtn,
    BaseHeader,
    BaseCard,
    ListItem
  },
  props: {
    value: Object
  },
  data () {
    return {
      isDialogLayer: false,
      isDialogEdit: false,
      isLoading: false,
      pagination: {
        page: '1',
        perPage: '10'
      },
      fileType: 'tif',
      nextStepStartAfter: 1,
      previousStepEndAfter: 3,
      paramsToUpload: {
        params: {
          passportId: this.value.properties.id
        }
      },
      passportLayers: []
    }
  },
  watch: {
    pagination: {
      handler () {
        this.$router.push({ query: this.pagination }).catch(err => {
        })
      },
      immediate: true,
      deep: true
    },
    isDialogLayer: {
      handler () {
        this.$emit('toggleDialogNested', this.isDialogLayer)
      }
    }
  },
  methods: {
    updateLayers () {
      this.$emit('updateLayers')
    },
    handleClickItem () {
      this.isDialogEdit = true
    },
    async handleParseCompleted () {
      this.isDialogLayer = false
      await this.getLayers()
    },
    handleSave () {
      this.$emit('save')
    },
    async getLayers () {
      this.isLoading = true
      const response = await this.$store.dispatch('api/get', {
        url: 'map/layers?project_id=' + this.value.properties.project_id
      })
      if (response) {
        this.passportLayers = response.data.models
      }
      this.isLoading = false
      return true
    }
  },
  mounted () {
    this.getLayers()
  }
}
</script>

<style scoped>

</style>
